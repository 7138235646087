import React, { Suspense, useEffect, useState } from "react";
import {
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import LoadingPage from "./components/statusPage/LoadingPage";
import Auth from "./hoc/Auth";
import MediaQuery from "react-responsive";
import { ConfigProvider } from 'antd';

const LoginPage = React.lazy(() =>
  import("./components/views/LoginPage/LoginPage")
);
const Dashboard = React.lazy(() =>
  import("./components/views/Dashboard/Dashboard")
);
const Header = React.lazy(() => import("./components/views/Header/Header"));
const Footer = React.lazy(() => import("./components/views/Footer/Footer"));
const NaviMenu = React.lazy(() => import("./components/views/NaviMenu/NaviMenu"));
const Attraction = React.lazy(() =>
  import("./components/views/Attraction/Attraction")
);
const Sequence = React.lazy(()=>  import("./components/views/Sequence/Sequence"));
const Member = React.lazy(() => import("./components/views/Member/Member"));
const MyPage = React.lazy(() => import("./components/views/MyPage/MyPage"));
const NotFoundPage = React.lazy(() =>
  import("./components/statusPage/NotFoundPage")
);

// import LoginPage from "./components/views/LoginPage/LoginPage";
// import Dashboard from "./components/views/Dashboard/Dashboard";
// import Header from "./components/views/Header/Header";
// import Footer from "./components/views/Footer/Footer";
// import NaviMenu from "./components/views/NaviMenu/NaviMenu";
// import Attraction from './components/views/Attraction/Attraction';
// import Member from './components/views/Member/Member';
// import MyPage from './components/views/MyPage/MyPage';
// import NotFoundPage from "./components/statusPage/NotFoundPage";

function App() {
  const location = useLocation();

  const user = useSelector((state) => state.auth.user);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  // const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isLoginPage = location.pathname === "/login";

  const AuthHeader = Auth(Header, true);
  const AuthNaviMenu = Auth(NaviMenu, true);
  const AuthLoginPage = Auth(LoginPage, false);
  const AuthDashboard = Auth(Dashboard, true);
  const AuthAttraction = Auth(Attraction, true);
  const AuthSequence = Auth(Sequence, true);
  const AuthMember = Auth(Member, true);
  const AuthMyPage = Auth(MyPage, true);
  const AuthNotFoundPage = Auth(NotFoundPage, false);
  const [Collapsed, setCollapsed] = useState(false);
  
  const toggleCollapsed = () => {
    setCollapsed(!Collapsed);
  }    

  return (
    <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#2facb2',
      },
    }}
  >
      <div>
        <Suspense fallback={<LoadingPage />}>
          {isLoggedIn && (
            <Header Collapsed={Collapsed} toggleCollapsed={toggleCollapsed} />
          )}
          <Container>
            <MediaQuery minWidth={750}>
              {isLoggedIn &&
              <Nav>
                <NaviMenu Collapsed={Collapsed} />
              </Nav>
              }
            </MediaQuery>
            <Content isLoginPage={isLoginPage}>
              <Routes>
                <Route path="/" element={<AuthDashboard />} />           
                <Route path="/login" element={<AuthLoginPage />} />   
                <Route path="/attractions" element={<AuthAttraction />} />
                <Route path="/sequences" element={<AuthSequence/>} />
                <Route path="/member" element={<AuthMember />} />
                <Route path="/mypage" element={<AuthMyPage />} />
                <Route path="/*" element={<AuthNotFoundPage />} />
              </Routes>
            </Content>
          </Container>
          <Footer isLogin={isLoginPage} />
        </Suspense>
      </div>
    </ConfigProvider>
  );
}

const Container = styled.div`
  padding-top: 50px;
  min-height: calc(100vh - 90px);  
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
`

const Nav = styled.div`
  text-align: start;
`;

const Content = styled.div`  
  padding: ${props=> props.isLoginPage ? '0px' : '0px 50px'};
  /* width: ${props=> props.isLoginPage ? '100%' : '85%'}; */
  width: 100%;
  margin: 0px auto;
`;
export default App;

import React from 'react'
import { Spin } from "antd";

function LoadingPage() {
  return (    
    <div style={{display:"flex", height:"100vh"}}>
    <Spin tip="Loading" size="large" style={{margin:"auto"}} />
  </div>
  )
}

export default LoadingPage
import React, { useEffect } from "react";
import { auth } from "../redux/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

export default function Auth(SpecificComponent, option, adminRoute = null) {
  function AuthenticationCheck(props) {
    const user = useSelector((state) => state.auth.user);        
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      
      dispatch(auth())
        .then((response) => {
          // console.log("response:::", response);          
          if (!response.payload.success) {
            if (option) {
              // console.log("로그인 안됨.");
              navigate("/login");
              return null;
            }
          } else {
            if (adminRoute && response.payload.result.permission === 2) {
              navigate("/");
              // console.log(
              //   "로그인o, 로그인 접근, 관리자x가 관리자페이지 접근"
              // );
              return null;
            } else {
              if (option === false) {
                navigate("/");
                // console.log("로그인o, 로그인 접근");
                return null;
              }
            }
          }
        })
        .catch((err)=> {
        if (location.pathname !== "/login") {
          // console.log("error!", err);
          navigate("/login");
          return null;
        }})
      }, []);

    if (!isLoggedIn && option) {
      navigate("/login");
      return null;
    }

    return <SpecificComponent {...props} user={user} />;
  }

  return AuthenticationCheck;
}

import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig ={
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, authReducer);

export default configureStore({
  reducer: {    
      auth : persistedReducer,      
  },
  middleware: (defaultMiddleware) =>
  defaultMiddleware({
    serializableCheck: false
  })
})

// const store = configureStore({
//   reducer: {
//     auth: authReducer,
//   },
// });


// export default store;
import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 1000,
    withCredentials: true,    
    headers: {
        // 'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': true,
        // 'Access-Control-Allow-Origin': 'http://localhost:3000',
        // 'Access-Control-Allow-Origin': 'http://cms.synergium.co.kr',
        // 'Access-Control-Allow-Credentials': true,
        // "Access-Control-Allow-Headers": "Content-Type"
    }    
})

export const upload = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // timeout: 1000,
    withCredentials: true,    
    headers: {
        'Content-Type': 'multipart/form-data'        
    }    
})

export default api;
import { createSlice } from "@reduxjs/toolkit";
import api from "../api";
import qs from "qs";
// import moment from "moment";

const initialState = {
  user: {
    id: null,
    username: null,
    name: null,
    number: null,
    description: null,
    permission: null,
  },
  isLoggedIn: false,
  error: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    loginSuccess(state, action) {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    loginFailure(state, action) {
      state.isLoggedIn = false;
      state.error = action.payload;
    },
    logoutUser(state) {
      state.isLoggedIn = false;
      state.user = null;
      // state = initialState;
    },
    authSuccess(state) {
      state.isLoggedIn = true;
    },
    authFailure(state, action) {
      state.isLoggedIn = false;
      state.error = action.payload;
    },
  },
});

export const {
  loginSuccess,
  loginFailure,
  logoutUser,
  authSuccess,
  authFailure,
} = authSlice.actions;
export default authSlice.reducer;

export const login =
  ({ username, password }) =>
  async (dispatch) => {
    try {
      // dispatch(loginStart());

      const userData = {
        username: username,
        password: password,
      };

      const response = await api.post("/api/sign/in", qs.parse(userData));
      
      if (response.data.success) {
        dispatch(
          loginSuccess({
            id: response.data.result.id,
            username: response.data.result.username,
            name: response.data.result.name,
            number: response.data.result.number,
            description: response.data.result.description,
            permission: response.data.result.permission,
          })
        );
        return {
          payload: response.data,
        };
      } else {
        dispatch(authFailure(response.data.result));
        return{
          payload: response.data,
        }
      }
    } catch (error) {
      dispatch(authFailure(error.message));
    }
  };

export const logout = () => async (dispatch) => {
  try {
    const response = await api.get("/api/sign/out");

    if (response.data.success) {
      return {
        payload: response.data,
      };
    } else {
      console.log("Logout Error", response.data.result);      
    }
  } catch (error) {
    return {
      payload: error.response,
    };
  } finally {
    dispatch(logoutUser());
  }
};

export const auth = () => async (dispatch) => {
  try {
    const response = await api.get("/api/sign");

    if (response.data.success) {
      dispatch(authSuccess({}));
      return {
        payload: response.data,
      };
    } else {
      dispatch(authFailure(response.data.result));
      return{
        payload: response.data,
      }
    }
  } catch (error) {
    // console.log("auth err ", error);
    dispatch(authFailure(error.message));
    return {
      payload: error.response,
    };
  }
};
